<template>
  <div tutor-my-card>
    <section-head :extra="$t('_.MS_coach_card')" :title="$t('_.MS_coach_card_eng')">
      <color-button @click="onDetailViewClick" type="light-gray">
        <span>자세히 보기</span>
      </color-button>
    </section-head>
    <div class="my-card">
      <div class="top">
        <div class="user-info">
          <div>
            <filled-badge v-for="tag in tags" :key="`coach-tags-${tag}`" :color="getTagColor(tag.priority)">{{ tag.name }}</filled-badge>
          </div>
          <em class="user-name">{{ username }}</em>
          <p class="user-rank">
            <lol-rank-text :value="rankCode" tag="span" />
            <rating-star :star-size="11" :value="ci.reviewRating" class="user-rating-mobile" />
          </p>
        </div>
        <client-only>
          <div class="toggle-online">
            <label>
              <span>{{ $t('_.MC_profile_open') }}</span>
              <toggle-button :block="isCoaching" :value="online" @input="v => online = v" @click.native="toggleHandler" />
            </label>
          </div>
        </client-only>
      </div>
      <div class="content">
        <div class="lesson-active">
          <div class="lesson-active-title">
            <svg-coaching svg-coaching />
            <p class="lesson-active-title-text">{{ $t('_.MC_profile_open') }}</p>
          </div>
          <ul>
            <li v-if="enableDoubleBundleSale">
              <svg-check svg-check />
              <span>{{ $t('_.PC_coachcard_2hours') }}</span>
            </li>
            <li v-if="enableTripleBundleSale">
              <svg-check svg-check />
              <span>{{ $t('_.PC_coachcard_3hours') }}</span>
            </li>
          </ul>
        </div>
        <ul class="lesson-types">
          <li v-for="lessonProduct in filteredLessonProducts" :key="lessonProduct.lessonProductId" class="lesson-type">
            <b>{{ lessonProduct.lesson.name }}</b>
            <point-icon :value="lessonProduct.price" shape="white" />
          </li>
        </ul>
      </div>
      <avatar :info="userInfo" :defaultImgType="'fruit'" class="user-avatar" dimension="272x272" userProfile />
      <rating-star :star-size="11" :value="ci.reviewRating" class="user-rating-desktop" readonly />
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import Avatar from '@shared/components/common/Avatar.vue';
import SvgCheck from '@shared/graphics/svg-check.vue';
import SectionHead from '@/views/components/coaching/SectionHead.vue';
import ToggleButton from '@/views/components/common/ToggleButton.vue';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import SvgCoaching from '@/views/graphics/svg-coaching.vue';
import RatingStar from '@/views/components/general/RatingStar.vue';
import LolRankText from '@/views/components/coaching/LolRankText.vue';
import CoachExpiredTokenModal from '@/views/components/coaching/CoachExpiredTokenModal.vue';
import coach from '@/mixins/coach';

export default {
  components: { LolRankText, RatingStar, SvgCheck, SvgCoaching, PointIcon, FilledBadge, Avatar, ToggleButton, ColorButton, SectionHead },
  lexicon: 'coaching',
  mixins: [coach],
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isOfflineMode: false,
  }),
  computed: {
    ci() {
      return /** @type {CoachDetail} */this.coachInfo || {};
    },
    userInfo() {
      return this.ci?.user || {};
    },
    coachId() {
      return this.ci?.coachId || '';
    },
    username() {
      return this.ci?.user?.nickname || '';
    },
    tags() {
      return /** @type{CoachDetail.tags} */ this.ci?.tags || [];
    },
    lessonProducts() {
      return /** @type{LessonProduct[]} */ this.ci?.lessonProducts || [];
    },
    filteredLessonProducts() {
      return this.lessonProducts.filter(ls => !ls.isHide);
    },
    enableDoubleBundleSale() {
      return this.ci?.enableDoubleBundleSale || false;
    },
    enableTripleBundleSale() {
      return this.ci?.enableTripleBundleSale || false;
    },
    isCoaching() {
      return this.ci?.status === 'COACHING';
    },
    online: {
      get() {
        return !this.isOfflineMode;
      },
      /**
       * @param {boolean} online
       */
      async set(online) {
        try {
          const { isOnline } = await this.$services.coaching.setOnlineStatus({ coachId: this.coachId, isOnline: online });
          this.isOfflineMode = !isOnline;
          this.$toast('_.COACH_STATUS_CHANGE_SUCCESS');
        } catch (err) {
          console.error(err?.code);
          if (err?.code === 'UNAUTHORIZED') {
            this.$modal(CoachExpiredTokenModal);
          } else {
            this.$toast(`_.COACH_STATUS_CHANGE_${err?.code || 'ERROR_DEFAULT'}`, { translate: true, type: 'fail' });
          }
        }
      },
    },
    rankCode() {
      return this.ci?.rank?.code || 'UNRANKED';
    },
  },
  watch: {
    coachInfo: 'updateValue',
  },
  methods: {
    /**
     * @param {CoachTag} tag
     *  */
    getTagColor(tag) {
      const colors = {
        PARTNER: 'orange',
        PRO: 'dark-magenta',
        OFFICIAL: 'red',
        OTP: 'dark-cyan',
        LOL_Q: 'dark-cyan',
        GCA: 'red',
        STREAMER: 'light-blue',
        1: 'red',
        0: 'light-blue',
      };
      return colors[tag] || 'dark-cyan';
    },
    updateValue() {
      this.isOfflineMode = (this.ci?.private?.isOfflineMode) || false;
    },
    onDetailViewClick() {
      this.$router.push(this.getCoachPageRoute(this.coachId));
    },
    toggleHandler() {
      if (this.isCoaching) this.$toast(`_.COACH_STATUS_CHANGE_COACH_IS_IN_LESSON`, { translate: true, type: 'fail' });
    },
  },
  beforeMount() {
    this.updateValue();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tutor-my-card] > [section-head] {.mb(20)}
[tutor-my-card] > .my-card {
  .rel;
  .top {
    .rel;.h(132);.bg('@{img}/coaching/my-card-bg.png');border-top-left-radius: 20px;border-top-right-radius: 20px;
    background-size: 100% 100%;
    .toggle-online {.abs;.rt(12, 12);.h(36);.flex;.flex-ai(center);
      label {.c(#fff);.flex;.flex-ai(center);}
      [toggle-button] {.ml(12)}
    }
    .user-info { .abs;
      [filled-badge] + [filled-badge] {.ml(6)}
    }
    .user-name { .c(#fff);.bold; }
    .user-rank {.c(#2c81ff);.bold}
  }
  .content { .bgc(#fff);.min-h(296);border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;.rel;}
  .lesson-active {border: #ebebf0 solid 1px;.br(20);.flex;
    .lesson-active-wrap {.flex;.flex-ai(center);}
    .lesson-active-title {.fs(16);.bold;}
    ul {
      li { .fs(12);.flex;.flex-ai(center);}
    }
    [svg-coaching] {fill: black;}
    [svg-check] {fill: rgb(131, 0, 255);.wh(14)}
  }
  .user-avatar {.br(100%);.abs;border: solid 2px white;z-index: 5;}
  .lesson-types {.w(100%)}
  .lesson-type {.flex;.flex-jc(space-between);.flex-ai(center);.bgc(@c-base-gray);.w(100%);}
  // ~tablet
  @media (max-width: calc(@screen-tp-min - 1px)) {
    .top {
      .toggle-online {
        label {.fs(12);}
      }
    }
    .content {.flex;.flex-column;.items-center;.p(16, 10, 14)}
    .lesson-active {
      .flex;.flex-column;.w(100%);.flex-ai(flex-start);.p(14);
      .lesson-active-title { .flex;.flex-ai(center);
      }
      ul {.flex;
        li {
          & + li {.ml(20);}
        }
      }
    }
    .user-info {.lt(110, 60);}
    .user-rank {.fs(12);.flex;
      > span { .mr(8)}
    }
    .user-name {.fs(16);.ellipsis;.block;.w(200);}
    .user-avatar {.wh(80);.lt(15, 40);}
    .user-rating-desktop {.hide}
    .user-rating-mobile {.lt(15, 130);}
    [svg-coaching] {.wh(30);.mr(10);}
    [svg-check] {.mr(3);}
    .lesson-type {.h(44);.br(20);.mt(8);.p(6, 12);
      [point-icon] .amount {.fs(12);}
      [point-icon] img {.wh(16);}
      [point-icon] .currency {.hide;}
    }
  }
  // desktop
  @media (@tp-up) {
    .top {
      .toggle-online {
        label {.fs(14)}
      }
    }
    .content {.pb(30);.pt(60);.pl(28);.pr(28);.flex;}
    .lesson-active {.flex;.flex-column;.wh(136, 188);.flex-ai(center);.flex-jc(center);
      .lesson-active-title {.flex;.flex-column;.items-center;.flex-jc(center);.mb(18);}
      ul {
        li + li {.mt(10);}
      }
    }
    .user-info {.lt(170, 35);}
    .user-rating-mobile {.hide}
    .user-rating-desktop {.abs;z-index: 5;.lt(15, 160)}
    .user-name {.fs(26);.ellipsis;.block;.w(500);}
    .user-rank {.fs(14);}
    .user-avatar {.wh(136);.lt(20, 25);}
    [svg-coaching] {.wh(20);.mr(2);.lt(24, 20);}
    [svg-check] {.mr(5)}
    .lesson-types {flex-grow: 1;.ml(12);}
    .lesson-type { .h(56);.p(8, 20);.br(20);.mb(10);
      > b {.fs(20);.c(#191919);.bold;}
    }
  }
}
</style>
