<template>
  <div questions-layout>
    <section-head :title="this.$t(`coaching.coachMenu.question.title`)" :extra="this.$t(`coaching.coachMenu.question.subtitle`)" />
    <slot name="nav-tab"></slot>
    <div class="filters">
      <slot name="filters"></slot>
    </div>
    <div class="questions">
      <div class="question-options">
        <p class="question-count">
          <slot name="filter-left">
          </slot>
        </p>
        <simple-tabs v-model="_sort">
          <simple-tab class="filter-tab" v-for="{text, value} in sortOptions" :key="value">{{ text }}</simple-tab>
        </simple-tabs>
      </div>
      <slot name="questions"></slot>
    </div>
  </div>
</template>
<script>
import SectionHead from '@/views/components/coaching/SectionHead.vue';
import SimpleTabs from '@/views/components/coaching/SimpleTabs.vue';
import SimpleTab from '@/views/components/coaching/SimpleTab.vue';

export default {
  components: { SimpleTab, SimpleTabs, SectionHead },
  props: {
    sortOptions: {
      type: Array,
      // [{ text, value }]
      default: () => ([]),
    },
    sort: {
      type: String,
      default: '',
    },
  },
  computed: {
    _sort: {
      get() {
        return this.sort;
      },
      set(v) {
        this.$emit('set-sort', v);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[questions-layout] {
  [section-head] {.mb(38)}
  > [nav-tab] { border-bottom: solid 1px #d8d8d8; }
  > .filters { .mt(32);
    [button-filter] + [button-filter] { .ml(12) }
  }
  > .questions {.mt(32);.pb(142);
    [question-preview] + [question-preview] { .mt(12); }
    > [area-more] {.mt(20);}
  }

  > .questions > .question-options {.flex;.flex-jc(space-between);.fs(14);.lh(21);.mb(12);
    .question-count {.c(#787781);font-weight: normal;font-style: normal; }
    .question-count > em {.c(#191919);.bold;}
  }

  @media (@tp-down) {
    > .questions > .question-options {.flex-column;.flex-ai(flex-start)}
    [section-head] {.mb(12)}
    > [nav-tab] { gap: 16px; }

  }
}
</style>
