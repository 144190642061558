<template>
  <div lesson-orders>
    <section-head :extra="$t('coaching.myPage.navigation.classroom')" :title="$t('coaching.myPage.navigation.classroom2')">
      <color-button v-show="isUser" :disabled="isBeginning" class="btn-left" type="light-gray" @click="onPrev">
        <svg-angle />
      </color-button>
      <color-button v-show="isUser" :disabled="!hasNext" class="btn-right" type="light-gray" @click="onNext">
        <svg-angle />
      </color-button>
      <color-button v-show="isUser" class="btn-go-classroom" type="gray" @click="goClassroom">
        자세히 보기
      </color-button>
    </section-head>
    <Slider v-if="isUser && hasLessonOrder" ref="lessonOrderSlide" :info="lessonOrders" :option="options" :space-between="16"
      preventTouchMove @changed="onSlideChange" @initialized="onSliderInit">
      <template v-slot:slide="{info}">
        <LessonOrder :key="info.id" :value="info" :isCoach="isCoach" @review="review" @cancel="cancel" />
      </template>
    </Slider>
    <div v-else-if="isUser && !hasLessonOrder" class="no-lesson">
      <svg-coaching />
      <p v-html="_formatText($t('coaching.MC_NO_DATA_MYMENU_LESSON'))"></p>
    </div>
    <div v-else class="guest">
      <svg-coaching />
      <p>{{ $t('coaching.MC_login_my_class') }}</p>
      <primary-button @click.native="loginBtnHandler" color="blue">로그인 하러가기</primary-button>
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import Slider from '@shared/components/common/Slider.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import LessonOrder from '@/views/components/coaching/user/LessonOrder.vue';
import SvgCoaching from '@/views/graphics/svg-coaching.vue';
import SectionHead from '@/views/components/coaching/SectionHead.vue';
import CoachLessonReviewModal from '@/views/components/coaching/CoachLessonReviewModal.vue';
import CoachLessonCancelModal from '@/views/components/coaching/CoachLessonCancelModal.vue';

export default {
  components: { PrimaryButton, SvgCoaching, LessonOrder, Slider, SvgAngle, ColorButton, SectionHead },
  data: () => ({
    now: 0,
    isEnd: false,
    isBeginning: true,
    /** @type{PagedLessonOrders || {}} */
    lessonOrderInfo: {},
    options: {
      breakpoints: {
        768: { slidesPerView: 2, slidesPerGroup: 2, slidesPerColumn: 1 },
        0: { slidesPerView: 1, slidesPerGroup: 1, slidesPerColumn: 2, slidesPerColumnFill: 'row' },
      },
    },
  }),
  computed: {
    /** @type{MyInfo} */
    myInfo() {
      return this?.$store?.state?.auth?.myInfo || {};
    },
    isCoach() {
      return this.myInfo?.isCoach || false;
    },
    userId() {
      return this.myInfo?.id || '';
    },
    isUser() {
      return this.userId !== '';
    },
    lessonOrders() {
      return this.lessonOrderInfo?.items || [];
    },
    hasLessonOrder() {
      return !!this.lessonOrders.length;
    },
    hasNext() {
      if (this.lessonOrderInfo?.totalCount > 0 && !this.isEnd) return true;
      return this.lessonOrderInfo?.hasNext || false;
    },
    query() {
      if (this.userId === '') return {};
      if (this.isCoach) return { coachUserId: this.userId };
      return { userId: this.userId };
    },
  },
  methods: {
    _formatText(str) {
      const _str = String(str);
      return _str.replaceAll('{br}', '<br>');
    },
    onPrev() {
      this.$refs.lessonOrderSlide.prev();
    },
    next() {
      this.$refs.lessonOrderSlide.next();
    },
    async onNext() {
      if (this.isEnd && this.hasNext) await this.fetchMore();
      this.$nextTick(() => {
        this.next();
      });
    },
    /**
     * @param {number} arg - slider pageIndex */
    onSlideChange() {
      this.now = this.$refs.lessonOrderSlide.now;
      this.isEnd = this.$refs.lessonOrderSlide?.slider?.isEnd;
      this.isBeginning = this.$refs.lessonOrderSlide?.slider?.isBeginning;
    },
    async fetchMore() {
      try {
        const lessonOrderInfo = await this.$services.coaching.getLessonOrders({
          ...this.query,
          cursor: this.lessonOrderInfo.nextCursor,
          size: 2,
          order: 'buyDateTime DESC',
        });
        this.lessonOrderInfo = {
          ...lessonOrderInfo,
          items: [...this.lessonOrderInfo.items, ...lessonOrderInfo?.items || []],
        };
      } catch (err) {
        console.error(err);
      }
    },
    onSliderInit(swiper) {
      this.isEnd = swiper.isEnd;
      this.isBeginning = swiper.isBeginning;
    },
    loginBtnHandler() {
      this.$services.auth.oAuthLogin(true);
    },
    async review(info) {
      await this.$modal(CoachLessonReviewModal, info);
      this.lessonOrderInfo = await this.$services.coaching.getLessonOrders({ ...this.query, cursor: 0, size: 2, order: 'buyDateTime DESC' });
    },
    async cancel(arg) {
      await this.$modal(CoachLessonCancelModal, arg);
      this.lessonOrderInfo = await this.$services.coaching.getLessonOrders({ ...this.query, cursor: 0, size: 2, order: 'buyDateTime DESC' });
    },
    async initLessonOrders() {
      this.lessonOrderInfo = await this.$services.coaching.getLessonOrders({ ...this.query, cursor: 0, size: 2, order: 'buyDateTime DESC' });
    },
    async lessonProductOrderStatusChangeHandler() {
      await this.initLessonOrders();
    },
    goClassroom() {
      this.$router.push({ name: 'CoachUserClasses' });
    },
  },
  async mounted() {
    if (!this.isUser) return;
    /** 추후 다른 컴포넌트에서도 해당 event를 수신하는 등 수정이 필요하면, service에서 바인딩 하는 이벤트 list를 만들고, 해당 리스트의 callback 기능들을 수행하는 방식으로 수정할 것. */
    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$bindPushEvent(privateChannel, 'lesson_product_order_started', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_ended', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_refunded', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderStatusChangeHandler);
    this.initLessonOrders();
  },
  beforeDestroy() {
    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_started', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_ended', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_refunded', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderStatusChangeHandler);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[lesson-orders] {
  > [section-head] {.mb(20)}
  [color-button] {
    &.light-gray { .bgc(#fff); border: none;}
  }
  .no-lesson { .flex; .flex-column; .items-center; .justify-center; .bgc(#fff); .h(200); .br(20);
    > svg {.fill(#000);.mb(16); .w(40); .h(40);}
  }
  .guest {
    .bgc(#fff);.h(280);.flex;.flex-column;.flex-jc(center);.flex-ai(center);.br(20);

    > svg {.fill(#000);.mb(16)}

    > p {.mb(16)}

    [primary-button] {.br(17);.p(8, 12, 8, 12);vertical-align: center}
  }
}

</style>
