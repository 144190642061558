<template>
  <div tutor-dashboard-item>
    <div class="item-title">
      <slot name="icon">
      </slot>
      <h1>
        <slot name="title">-</slot>
      </h1>
      <slot name="tooltip"></slot>
    </div>
    <em class="text-big">
      <slot name="big"></slot>
    </em>
    <p class="text-small">
      <slot name="small"></slot>
    </p>
    <div class="item-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tutor-dashboard-item] {.flex;.flex-column;.min-w(182);.h(208);.br(20);.bgc(#fff);.p(20);.noto;.flex-ai(flex-start);
  .item-title { .flex;.h(40);.flex-ai(center);.flex-jc(flex-start);.w(100%);
    h1 {.c(#191919);.fs(14);.bold;.ellipsis;flex: 1;display: inline-block;}
    svg:not([svg-tooltip]), img { .max-w(40);.max-h(40);.mr(8);
      * {.fill(#191919);}
    }
  }
  .text-big {.c(#191919);.fs(24);.bold;.mb(8);.ellipsis;.w(100%);}
  .text-small {.c(@c-text-dark);flex-grow: 1;.bold;.fs(12);vertical-align: middle;}
  .item-bottom {
    [color-button] {.br(12);.h(30);.lh(0)}
  }
  [rating-star] {.inline;}
  [svg-tooltip] {.rt(0, 0);}
  [svg-tootlip] path {.fill(rgb(120, 119, 129));}
}
</style>
