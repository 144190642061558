<template>
  <div tutor-dashboard>
    <section-head :title="$t('_.MS_DASHBOARD_TITLE_1')" :extra="$t('_.MS_DASHBOARD_TITLE_2')" />
    <div class="dashboard-content">
      <tutor-dashboard-item>
        <template #title>{{ $t('_.MS_coach_grade') }}</template>
        <template #tooltip>
          <svg-tooltip v-popover.top.right="{text: '_.MC_DASHBOARD_GRADE_TOOLTIP', maxWidth: 200}" />
        </template>
        <template #icon>
          <svg-coach />
        </template>
        <template #big>{{ gradeText }}</template>
        <template #bottom>
          <button class="dashboard-button-disabled" v-popover.bottom.center="{text: '_.MC_TOOLTIP_SCHEDULED_OPEN'}">{{ $t('_.BA_coach_gradeView') }}</button>
        </template>
      </tutor-dashboard-item>
      <tutor-dashboard-item>
        <template #title>{{ $t('_.MS_coaching') }}</template>
        <template #tooltip>
          <svg-tooltip v-popover.top.right="{text: '_.MC_TOOLTIP_CLASS_COUNT', maxWidth: 200}" />
        </template>
        <template #icon>
          <svg-monitor />
        </template>
        <template #big>{{ $t('_.MC_current_point', { amount: lessonOrderAmount }) }}</template>
        <template #small>
          <span>{{ $t('_.MC_coaching_total', { count: lessonCount }) }}</span>
        </template>
        <template #bottom>
          <button class="dashboard-button-enabled" @click="classCountBtnHandler">{{ $t('_.BA_coaching_class_count') }}</button>
        </template>
      </tutor-dashboard-item>
      <tutor-dashboard-item class="lesson-income">
        <template #icon>
          <img src="/img/coaching/cash.svg" alt="icon-cash" />
        </template>
        <template #title>{{ $t('_.MS_main_education_gainpoint') }}</template>
        <template #tooltip>
          <svg-tooltip v-popover.top.right="{text: '_.MC_TOOLTIP_CLASS_AMOUNT', maxWidth: 200}" />
        </template>
        <template #big>{{ $t('_.PC_store_cost2', { amount: lessonIncome }) }}</template>
        <template #small>{{ $t(['_.settlementHistory.status', lessonIncomeStatus].join('.')) }}</template>
        <template #bottom>
          <button class="dashboard-button-enabled" @click="lessonIncomeBtnHandler">{{ $t('_.BA_calculate_point') }}</button>
        </template>
      </tutor-dashboard-item>
      <tutor-dashboard-item>
        <template #title>{{ $t('_.MC_answer') }}</template>
        <template #tooltip>
          <svg-tooltip v-popover.top.right="{text: '_.MC_TOOLTIP_COMMENT_COUNT', maxWidth: 200}" />
        </template>
        <template #icon>
          <svg-answer />
        </template>
        <template #big>{{ $tc('_.MS_answer_all', Number(answerAmount)) }}</template>
        <template #small>답변 수 상위 {{ answerPercent }}%</template>
      </tutor-dashboard-item>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';
import SectionHead from '@/views/components/coaching/SectionHead.vue';
import TutorDashboardItem from '@/views/components/coaching/user/TutorDashboardItem.vue';
import {
  SvgTutorDashboardCoach,
  SvgTutorDashboardMonitor,
  SvgTutorDashboardAnswer,
} from '@/views/graphics/tutor-dashboard/LazyLoadings';
import SvgTooltip from '@/views/graphics/svg-tooltip.vue';

export default {
  lexicon: 'coaching',
  components: {
    SvgTooltip,
    SvgAnswer: SvgTutorDashboardAnswer,
    SvgMonitor: SvgTutorDashboardMonitor,
    SvgCoach: SvgTutorDashboardCoach,
    TutorDashboardItem,
    SectionHead,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    d() {
      return /** @type{TutorDashboard} */ this.value || {};
    },
    gradeText() {
      const currentGrade = this.d?.grade?.currentGrade || '';
      const code = `coaching.MC_coach_grade_${currentGrade.toLowerCase()}`;
      return this.$te(code) ? this.$t(code) : '';
    },
    lessonCount() {
      return commaDecimal(this.d?.order?.count || 0);
    },
    lessonOrderAmount() {
      return commaDecimal(this.d?.order?.totalAmount || 0);
    },
    lessonIncome() {
      return commaDecimal(this.d?.settlement?.depositAmount || 0);
    },
    lessonIncomeStatus() {
      return this.d?.settlement?.status ?? 'NONE';
    },
    answerAmount() {
      return commaDecimal(this.d?.answer?.answerCount || 0);
    },
    answerPercent() {
      return this.d?.answer?.percent || '0';
    },
  },
  methods: {
    classCountBtnHandler() {
      this.$router.push({ name: 'CoachSalesHistory' });
    },
    lessonIncomeBtnHandler() {
      this.$router.push({ name: 'CoachSettlementHistory' });
    },
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';
[tutor-dashboard] {
  [section-head] { .mb(18); }
  .dashboard-content {.flex;.flex-wrap;}
  .dashboard-button-enabled {.br(12); .bgc(#ebebf0); .c(#787781); .fs(14); .p(10); }
  .dashboard-button-disabled {.br(6); .h(40); .lh(38); .-box; transition: background-color .3s, border-color .3s; .p(0, 10); .bgc(#545454); cursor: not-allowed; .o(0.5);
    @{hover-press} { .bgc(#545454); .-a(#545454); }
  }
  @media (max-width: 472px) {
    .dashboard-content {gap: 12px;}
    [tutor-dashboard-item] { flex-grow: 1; .w(calc(50% - 6px)); }
    [tutor-dashboard-item] + [tutor-dashboard-item] {}
  }
  @media (min-width: 473px) {
    [tutor-dashboard-item] { flex-grow: 1;flex-basis: calc(50% - 12px);.mr(12);
      &:nth-child(2n) {.mr(0)}
      &:nth-child(3), &:nth-child(4) {.mt(12)}
    }
  }
}
</style>
