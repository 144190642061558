<template>
  <div tutor-home>
    <div class="user-area">
      <client-only>
        <div class="user-area-inner">
          <lesson-orders />
          <div class="user-area-inner-alt">
            <tutor-dashboard :value="tutorDashboard" />
            <tutor-my-card :coach-info="userCoachInfo" />
          </div>
        </div>
      </client-only>
    </div>
    <div class="content-area">
      <div class="content-area-inner">
        <questions-layout ref="questions" :question-count="questionCount" :sort="_order" :sort-options="sortOptions" @set-sort="setOrder">
          <template #nav-tab>
            <nav-tab v-model="questionType" :items="navList" />
          </template>
          <template v-if="questionType === 'waiting'" #filter-left>
            <span>총</span>
            <em>{{ `${questionCount}` }}</em>
            <span>건의 질문이 코치님의 답변을 기다리고 있어요</span>
          </template>
          <template v-else-if="questionType === 'answered'" #filter-left>
            <span>총</span>
            <em>{{ `${questionCount}` }}</em>
            <span>건의 답변을 완료하셨어요!</span>
          </template>
          <template #filters>
            <button-filter :class="{active: !hasLane && !hasChamp}" @click="resetFilter">전체</button-filter>
            <button-filter v-show="!hasLane" @click="setLane">
              <span>포지션</span>
            </button-filter>
            <button-filter v-show="hasLane" class="active" @click="setLane">
              <span>포지션 :</span>
              <em>{{ lolLaneText }}</em>
            </button-filter>
            <button-filter v-show="!hasChamp" @click="setChamp">
              <span>챔피언</span>
            </button-filter>
            <button-filter v-show="hasChamp" class="active" @click="setChamp">
              <span>챔피언 :</span>
              <lol-champ-data v-slot="{ champText }" :champ-code="lolChamp" tag="em">{{ champText }}</lol-champ-data>
            </button-filter>
          </template>
          <template #questions>
            <no-content v-if="questionCount === 0 && questionType === 'answered'" alt="eyes" src="/img/coaching/seeking-eyes.svg">
              코치님의 답변을 기다리고 있습니다.
            </no-content>
            <no-content v-else-if="questionCount === 0 && questionType === 'waiting'" alt="memopad" src="/img/coaching/memopad.svg">
              아직 질문을 작성하지 않으신 것 같아요.
            </no-content>
            <question-preview v-for="q in questions" :key="q.question.boardWriteId" :question="q" clickable @examine="onQuestionExamine" />
            <area-more v-if="hasMore" @click="getMore" />
          </template>
        </questions-layout>
        <div class="chatting-area">
          <CoachList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import { getter } from '@shared/utils/storeUtils';
import LessonOrders from '@/views/components/coaching/user/LessonOrders.vue';
import TutorDashboard from '@/views/components/coaching/user/TutorDashboard.vue';
import TutorMyCard from '@/views/components/coaching/user/TutorMyCard.vue';
import NavTab from '@/views/components/coaching/question/NavTab.vue';
import QuestionsLayout from '@/views/components/coaching/question/QuestionsLayout.vue';
import questionsFilter from '@/mixins/questionsFilter';
import ButtonFilter from '@/views/components/coaching/question/ButtonFilter.vue';
import QuestionPreview from '@/views/components/coaching/question/QuestionPreview.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import { LOL_POSITION_TEXT } from '@/constants/lol';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import NoContent from '@/views/components/coaching/question-detail/NoContent.vue';
import LolChampData from '@/views/components/coaching/LolChampData.vue';

export default {
  name: 'TutorHome',
  components: { LolChampData, NoContent, AreaMore, QuestionPreview, ButtonFilter, QuestionsLayout, NavTab, TutorMyCard, LessonOrders, TutorDashboard, CoachList },
  lexicon: 'coaching',
  mixins: [questionsFilter, cursorFetcher({
    questionInfo: ({ cursor, route, services, questionType, lolLane, lolChamp, order, orderBy }) => {
      const { gameId } = route.params;
      const opt = { cursor, order, orderBy };
      const category = [];
      if (lolLane && lolLane !== '') {
        category.push({ groupName: 'LOL_LANE', values: [lolLane] });
      }
      if (lolChamp && lolChamp !== '') {
        category.push({ groupName: 'LOL_CHAMPION', values: [lolChamp] });
      }
      if (category.length >= 1) opt.category = category;
      if (questionType === 'answered') opt.q = 'hasMyAnswer eq true';
      else opt.q = 'hasMyAnswer eq false';
      return services.coaching.getQuestions({ size: 5, gameId, ...opt });
    },
  })],
  data: () => ({
    gameInfo: null,
    topBannerInfo: null,
    userInfo: null,
    userCoachInfo: null,
    userSectionOpenMobile: false,
    tutorDashboard: null,
    questionInfo: null,
    lolChamps: null,
  }),
  metaInfo() {
    return {
      title: this.$t('meta.tutorLoL.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.tutorLoL.title') },
        { vmid: 'description', content: this.$t('meta.tutorLoL.description') },
        { vmid: 'url', content: this.$t('meta.tutorLoL.url') },
        { vmid: 'keywords', content: this.$t('meta.tutorLoL.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    navList() {
      return [
        { text: '내 답변을 기다리는 질문', value: 'waiting' },
        { text: '내가 답변한 질문', value: 'answered' },
      ];
    },
    sortOptions() {
      return [
        { text: '답변 적은 순', value: 'commentCount asc' },
        { text: '질문 최신 순', value: 'createdDatetime desc' },
      ];
    },
    orderBy() {
      return this.$route.query?.orderBy || 'createdDatetime';
    },
    order() {
      return this.$route.query?.order || 'desc';
    },
    _order: {
      get() {
        return `${this.orderBy} ${this.order}`;
      },
      set(strOrder) {
        const [orderBy, order] = strOrder.split(' ');
        const query = this.getMergedQuery({ orderBy, order });
        this.$router.push({ query });
      },
    },
    questionType: {
      get() {
        return this?.$route?.query?.['question-type'] || 'waiting';
      },
      set(questionType) {
        const query = this.getMergedQuery({ 'question-type': questionType });
        this.$router.push({ query });
      },
    },
    q() {
      return /** @type {PagedQuestions} */this.questionInfo;
    },
    questions() {
      return /** @type{PreviewQuestion[]} */ this.q?.items || [];
    },
    hasMore() {
      return this.q?.hasNext;
    },
    questionCount() {
      return this.q?.totalCount || 0;
    },
    lolLaneText() {
      return this.$t(`coaching.${LOL_POSITION_TEXT[this.lolLane]}`);
    },
    isCoach: getter('auth', 'isCoach'),
  },
  beforeDestroy() {
    this.$unbindPushEvent('public-coach', 'coach_status_changed', this.myStatusUpdate);
  },
  methods: {
    getMore() {
      return this.fetchMore(this.questionInfo);
    },
    setOrder(order) {
      this._order = order;
    },
    onQuestionExamine(boardWriteId) {
      this.routeName('CoachQuestion', { gameId: 'lol', boardWriteId });
    },
    myStatusUpdate(e) {
      const { coachId, status } = e;
      if (coachId === this.userCoachInfo?.coachId) {
        this.userCoachInfo.status = status;
      }
    },
    async resetData() {
      const userInfo = this.$store.state.auth.myInfo;
      const tutorDashboardTask = this.$services.coaching.getTutorDashboard();
      const tutorCoachInfoTask = this.$services.coaching.getCoachMe();
      [this.tutorDashboard, this.userCoachInfo] = await Promise.all([tutorDashboardTask, tutorCoachInfoTask]);
      const ipList = this.$store.state.info.games;
      const gameId = this.$route?.params?.gameId || 'lol';
      const gameInfo = { id: gameId.toLowerCase(), ipList };
      if (userInfo) gameInfo.stats = await this.$services.user.getUserLolSeasonInfo(userInfo.id);
      const lolLane = this.$route.query?.['lol-lane'] || '';
      const lolChamp = this.$route.query?.['lol-champ'] || '';
      const questionType = this.$route.query?.['question-type'] || 'waiting';
      const orderBy = this.$route.query?.orderBy || 'createdDatetime';
      const order = this.$route.query?.order || 'desc';
      await this.fetchInitAll({ lolLane, lolChamp, questionType, order, orderBy });
    },
  },
  watch: {
    $route: 'resetData',
  },
  async mounted() {
    if (!this.isCoach) {
      await this.$router.replace('/');
      return;
    }
    const { chatUrl, scroll } = this.$route.query;
    if (chatUrl) {
      await this.$services.chat.openChatWithUrl(this, chatUrl);
      await this.$router.push({ query: { chatUrl: '' } });
    }

    if (scroll === 'qna') {
      setTimeout(() => {
        const qEl = this.$refs?.questions?.$el;
        const rect = qEl.getBoundingClientRect();
        window.scrollTo({ top: rect.top - 64, behavior: 'smooth' });
      }, 300);
    }
    await this.resetData();
    this.$bindPushEvent('public-coach', 'coach_status_changed', this.myStatusUpdate);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tutor-home] {
  > .user-area {.bgc(#f5f5f7);.pb(60);}
  > .user-area > .user-area-inner { .max-w(1280);.m(0, auto);.pt(60);}
  > .user-area > .user-area-inner-alt {}
  > .content-area {.bgc(#ebebf0);.pt(60)}
  > .content-area > .content-area-inner { .max-w(1280);.m(0, auto);}
  @media (@tp-down) {
    > .content-area > .content-area-inner { .p(0, 20); }
    > .user-area {.p(0, 20);}
    .user-area-inner-alt {.pt(30)}
    [tutor-my-card] {.pt(30)}
  }
  @media (max-width: calc(@screen-tl-min - 1px)) {
    .chatting-area {.hide;}
  }
  @media (@tp-up) and (max-width: calc(@screen-ds-min - 1px)) {
    > .content-area > .content-area-inner { .p(0, 20)}
    > .user-area {.p(0, 20)}
    [tutor-dashboard] {.pt(60);.pb(20)}
    .user-area-inner-alt {.pt(60);.flex;flex-direction: column-reverse;}
  }
  @media (@ds-up) {
    [tutor-my-card] {flex-grow: 1;}
    [tutor-dashboard] {.w(450);.ml(57)}
    .user-area-inner-alt {.pt(60);.flex;flex-direction: row-reverse;}
    > .content-area > .content-area-inner {.flex;
      // '내가 답변한 질문'으로 볼 경우(question-type=answered)
      // 레이아웃이 깨지는 버그가 있어서 flex-grow로 처리하지 않고 calc로 임시 처리함
      [questions-layout] {.w(calc(100% - (375px + 57px)))}
      .chatting-area { .ml(auto); }
    }
  }
}
</style>
